<template>
	<div class="loginContent">
		<img class="logo" src="../../res/img/logo.png">

		<!-- 登录表单 -->
		<div style="margin-top:30px">

			<!-- 登陆方式切换 -->
			<div style="text-align: center;">

				<el-radio-group v-model="login_way" size="samll">
					<el-radio-button label="2">密码登录</el-radio-button>
					<el-radio-button label="1">验证码登录</el-radio-button>
				</el-radio-group>
			</div>
			
			<div style="margin-top:10px">

				<!-- 验证码登录 -->
				<el-form status-icon label-width="px" label-position="left" v-if="login_way==1">
					<el-form-item prop="tel">
						<el-input v-model="tel" placeholder="请输入手机号" clearable>
							<template slot="prepend">手机号</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="check_code">
						<el-input v-model="check_code" placeholder="请输入验证码" clearable>
							<template slot="prepend">验证码</template>
							<el-button @click="get_verify" type="success" slot="append" style="width:112px">{{verifyTips}}</el-button>
						</el-input>
					</el-form-item>
				</el-form>

				<!-- 密码登录 -->
				<el-form status-icon label-width="0px" label-position="left" v-if="login_way==2">
					<el-form-item prop="tel">
						<el-input v-model="tel" placeholder="请输入手机号" clearable>
							<template slot="prepend">手机号码</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="check_code">
						<el-input v-model="login_pwd" type="password" :show-password="true" placeholder="请输入登录密码" clearable>
							<template slot="prepend">登录密码</template>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<div class="sub_btn">
			<el-button @click="login" style="width: 100%;" type="primary">立即登录</el-button>
		</div>
	</div>
</template>

<script>
	import {mapMutations} from 'vuex'
	import bg from '@/my/bg.js'
	export default {
		data() {
			return {
				login_way:2,//登陆方式(1:短信验证码,2:密码)
				tel: '',
				login_pwd:'',
				check_code: '',
				sec:60,
				verifyTips: '获取验证码',

				login_lock:false,
				check_code_lock:false,
			}
		},
		created() {
			
			bg.init()
		},
		methods: {
			
			...mapMutations(["end_of_login"]),
			
			//登录
			login(){

				//是否锁定
				if(this.login_lock){
					this.$my.other.msg({
						type:'warning',
						str:'登陆中,不要重复点击',
					})
					return;
				}

				//电话号码校验
				if(!this.$my.check.is_tel(this.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误',
					})
					return;
				}

				//通过验证码登录
				if(this.login_way==1){

					this.login_by_check_code();

				//通过密码登录
				}else if(this.login_way==2){

					this.login_by_pwd();
				}
			},

			//通过密码登录
			login_by_pwd(){

				//验证码校验
				if(!this.$my.check.is_login_pwd(this.login_pwd)){
					this.$my.other.msg({
						type:'warning',
						str:'密码需包含字母，符号或者数字中至少两项且长度超过6位数，最多不超过16位数',
					})
					return;
				}

				//登录
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'login_by_pwd',
						tel:this.tel,
						login_pwd:this.$my.md5(this.login_pwd),
					},
					callback:(data)=>{

						//缓存用户数据
						this.end_of_login({
							is_login:1,
							user_info:data.user_info
						});

						this.$router.push('/pages');
					}
				});
			},

			//通过验证码登录
			login_by_check_code(){

				//验证码校验
				if(!(this.$my.check.is_num(this.check_code)&&this.check_code.length==4)){
					this.$my.other.msg({
						type:'warning',
						str:'验证码只能是4位数字',
					})
					return;
				}

				//登录
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'login_by_check_code',
						tel:this.tel,
						ide:this.check_code,
					},
					callback:(data)=>{

						//缓存用户数据
						this.end_of_login({
							is_login:1,
							user_info:data.user_info
						});

						this.$router.push('/pages');
					}
				});
			},
			
			//获取验证码
			get_verify(){

				//是否锁定
				if(this.check_code_lock){
					this.$my.other.msg({
						type:'warning',
						str:'60秒内不能重复获取验证码',
					})
					return;
				}

				//电话号码校验
				if(!this.$my.check.is_tel(this.tel)){
					this.$my.other.msg({
						type:'warning',
						str:'电话号码格式有误',
					})
					return;
				}

				//发送中...
				this.verifyTips="发送中...";
				
				//发送请求
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'send_ide_for_login_by_admin',
						tel:this.tel
					},
					callback:(data)=>{
						
						//锁定发送按钮
						this.check_code_lock=true;
						
						this.verifyTips=this.sec+"S";

						//开始倒计时
						this.interval_id=setInterval(()=>{
							this.sec--;
							this.verifyTips=this.sec+"S";
							if(this.sec==0){
								this.check_code_lock=false;
								this.sec=60;
								this.verifyTips="重新发送";
								clearInterval(this.interval_id)
							}
						},1000);
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>
	.bg-paper{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: #666;
		//background: url(../../res/img/bg-paper.jpg) no-repeat 50%;
		background-size: cover;
	}

	.loginContent {
		width: 340px;
		position: absolute;
		top: 100px;
		left: calc(50% - 170px);

		.logo{
			width: 100px;
			display: block;
			margin: auto;
		}
	}

	.bottom_links{
		
		padding: 0 10px;

		.links{
			margin-top: 10px;
			display: flex;
			flex-wrap: wrap;
			.item{
				font-size: 12px;
			}
		}
	}

	.sub_btn{
		margin-top: 15px;
	}
</style>